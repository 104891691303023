<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="5">
        <BaseDateInput v-model="month" :disabled="loading" append-icon="event" :clearable="true" dateFormat="MMMM YYYY"
                            :label="$t('generic.lang_from')"
                            :loading="loading"
                            :rules="[v => !!v]" dense outlined
                            no-title
                            :max="$moment().format('YYYY-MM')"
                            type="month"/>
      </v-col>
      <v-col cols="12" sm="5">
          <v-select :label="$t('generic.lang_cashierID')" clearable
                        :items="filteredCashierIDs" item-value="value" item-text="name"
                        dense outlined v-model="cashierId" chips small-chips deletable-chips
              />
        </v-col>
      <!-- <v-col cols="12" sm="5">
        <v-text-field v-model="endMonth" :disabled="loading"
                      :label="$t('generic.lang_to')"
                      :loading="loading"
                      dense
                      outlined
                      clearable
                      type="month"/>
      </v-col> -->

      <v-col class="pt-2" cols="12" sm="2">
        <v-btn block class="mx-auto elevation-0" color="primary" :loading="loading" :disabled="loading || (!month || !cashierId)" @click="filter()">
          <v-icon>filter_list</v-icon>
          {{ $t("generic.lang_filter") }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <monthly-report-details-component v-if="showDetails" ref="monthlyDetails" :month="month" :cashierId="cashierId" @loading="loading=$event" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import MonthlyReportDetailsComponent from "./MonthlyReportDetailsComponent";
import BaseDateInput from "@/components/common/BaseDateInput";

export default {
  name: "MonthlyArchiveComponent",
  components: {MonthlyReportDetailsComponent, BaseDateInput},
  data(){
    return {
      month: this.$moment().format('YYYY-MM'),
      cashierId: null,
      loading:false,
      showDetails:false
    }
  },
  methods: {
    filter() {
      if(this.showDetails && this.$refs.monthlyDetails){
        this.$refs.monthlyDetails.loadData();
        return;
      }

      this.showDetails = true;
    },
  },
  computed: {
    ...mapGetters({
      cashierIDs:"cashierIDs/cashiers",
    }),
    filteredCashierIDs(){
      return this.cashierIDs.map(item => ({
        name:this.$t('generic.lang_kasse') +' '+ item.id + (item.aliasName ? ` (${item.aliasName}) ` : ''),
        value:item.id
      }))
    },
  },
}
</script>

<style scoped>
.buttons {
  min-width: 200px !important;
  max-width: 300px !important;
  height: 250px !important;
  cursor: pointer !important;
}

.icons {
  font-size: 100px !important;
}
</style>